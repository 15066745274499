<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" content="编辑促销活动总结" class="margin-bottom margin-top-xs"></el-page-header>
		<div>
			<!-- <el-divider content-position="left">基本信息</el-divider> -->
			<div class="form_box">
				<el-form ref="form" :model="form" label-width="120px" class="flex flex-wrap" v-loading="loading">
					<el-form-item label="填写人" prop="input_by" :rules="ruless.required">
						<div class="form-item-box">
							<el-input v-model="form.input_by" placeholder="请输入填写人"></el-input>
						</div>
					</el-form-item>

					<el-form-item label="促销品牌" prop="brand" :rules="ruless.required">
						<div class="form-item-box">
							<el-input v-model="form.brand" placeholder="请输入促销品牌"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="促销时间" prop="activity_time" :rules="ruless.required">
						<div class="form-item-box" style="width: 100%;">

							<el-date-picker v-model="form.activity_time" prop="startTime" type="date"
								format='yyyy-MM-dd' value-format="yyyy-MM-dd" :picker-options="pickerOptionsStart"
								placeholder="选择开始时间">
							</el-date-picker>
							<el-date-picker v-model="form.activity_time_end" prop="endTime" type="date"
								format='yyyy-MM-dd' value-format="yyyy-MM-dd" :picker-options="pickerOptionsEnd"
								placeholder="选择结束时间">
							</el-date-picker>


						</div>
					</el-form-item>
					<el-form-item label="店名" prop="merchant_name" :rules="ruless.required">
						<div class="form-item-box">
							<el-input v-model="form.merchant_name" placeholder="请输入店名"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="店主" prop="merchant_user" :rules="ruless.required">
						<div class="form-item-box">
							<el-input v-model="form.merchant_user" placeholder="请输入店主"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="地址" prop="address" :rules="ruless.required">
						<div class="form-item-box">
							<el-input v-model="form.address" placeholder="请输入地址"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="电话" prop="phone" :rules="[ruless.required,ruless.phone]">
						<div class="form-item-box">
							<el-input v-model="form.phone" placeholder="请输入电话"></el-input>
						</div>
					</el-form-item>

					<el-form-item label="费用支出" prop="fee" :rules="ruless.required">
						<div class="form-item-box">
							<el-input v-model="form.fee" placeholder="请输入费用支出"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="参加人员" style="width: 100%;" prop="join_people" :rules="ruless.required">
						<div class="form-item-box" style="width: 100%;">
							<el-input type="textarea" :rows="4" v-model="form.join_people" placeholder="请输入参加人员">
							</el-input>
						</div>
					</el-form-item>
					<el-form-item label="活动效果" style="width: 100%;" prop="effect" :rules="ruless.required">
						<div class="form-item-box" style="width: 100%;">
							<el-input type="textarea" :rows="4" v-model="form.effect" placeholder="请输入活动效果"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="销售额" prop="sales_volume" :rules="ruless.required">
						<div class="form-item-box">
							<el-input v-model="form.sales_volume" placeholder="请输入销售额"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="营业额" prop="turnover" :rules="ruless.required">
						<div class="form-item-box">
							<el-input v-model="form.turnover" placeholder="请输入营业额"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="其他" prop="other" :rules="ruless.required">
						<div class="form-item-box">
							<el-input v-model="form.other" placeholder="请输入其他"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="部门负责人" prop="charger" :rules="ruless.required">
						<div class="form-item-box">
							<el-input v-model="form.charger" placeholder="请输入部门负责人"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="活动成败分析" style="width: 100%;" prop="analysis" :rules="ruless.required">
						<div class="form-item-box" style="width: 100%;">
							<el-input type="textarea" :rows="4" v-model="form.analysis" placeholder="请输入活动成败分析">
							</el-input>
						</div>
					</el-form-item>
					<el-form-item label="建议与要求" style="width: 100%;" prop="proposal" :rules="ruless.required">
						<div class="form-item-box" style="width: 100%;">
							<el-input type="textarea" :rows="4" v-model="form.proposal" placeholder="请输入建议与要求">
							</el-input>
						</div>
					</el-form-item>
					<el-form-item label="部门鉴定" style="width: 100%;" prop="appraisal" :rules="ruless.required">
						<div class="form-item-box" style="width: 100%;">
							<el-input type="textarea" :rows="4" v-model="form.appraisal" placeholder="请输入部门鉴定">
							</el-input>
						</div>
					</el-form-item>
					<el-form-item label="鉴定日期" prop="appraisal_date" :rules="ruless.required">
						<div class="form-item-box">
							<el-date-picker v-model="form.appraisal_date" type="date" placeholder="选择鉴定日期"
								format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" style="width: 100%;">
							</el-date-picker>
						</div>
					</el-form-item>
					<el-form-item label="填写时间" prop="created_at" :rules="ruless.required">
						<div class="form-item-box">
							<el-date-picker v-model="form.created_at" type="date" placeholder="填写时间"
								format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" style="width: 100%;">
							</el-date-picker>
						</div>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit()">保存</el-button>
						<el-button @click="$router.back()">取消</el-button>
					</el-form-item>
				</el-form>
			</div>

		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import ruless from "../../../common/common.rules";
	// 行政区划npm版本
	// import ChinaLocation from "china-location";
	// import list from "china-location/dist/location.json";
	export default {
		data() {
			return {
				ruless,
				loading: false,
				form: {
					id: this.$route.params.id, // int 服务ID
					input_by: "",
					brand: "",
					activity_time: "",
					activity_time_end: "",
					merchant_name: "",
					merchant_user: "",
					address: "",
					phone: "",
					fee: "",
					join_people: "",
					effect: "",
					sales_volume: "",
					turnover: "",
					other: "",
					charger: "",
					analysis: "",
					proposal: "",
					appraisal: "",
					appraisal_date: "",
					created_at: ""
				},
				rules: {
					startTime: [{
						required: true,
						message: '请选择开始时间',
						validator: this.pickerOptionsStart,
						trigger: 'change'
					}],
					endTime: [{
						required: true,
						message: '请选择结束时间',
						validator: this.pickerOptionsEnd,
						trigger: 'change'
					}]
				},
				// 开始结束日期限制
				pickerOptionsStart: {
					disabledDate: time => {
						let endDateVal = this.form.activity_time_end;
						if (endDateVal) {
							return time.getTime() > new Date(endDateVal).getTime();
						}
					}
				},
				// 结束日期限制
				pickerOptionsEnd: {
					disabledDate: time => {
						let beginDateVal = this.form.activity_time;
						if (beginDateVal) {
							return (time.getTime() < new Date(beginDateVal).getTime() - 1 * 24 * 60 * 60 * 1000)
						}
					}
				}

			};
		},

		mounted() {
			if (this.form.id) {
				this.getInfo()
			}
		},
		methods: {

			//获取详情
			getInfo() {
				this.$api.table.activityDetail({
					id: this.form.id
				}).then(res => {
					console.log(res)
					this.form = res.data
				})
			},

			onSubmit() {
				console.log(this.submitForm)
				this.$refs.form.validate(valid => {
					if (valid) {
						const loading = this.$loading();
						this.$api.table.addActivity(this.form).then(res => {
								loading.close();
								if (res.code == 200) {
									// 保存成功
									this.$message.success(`保存成功`);
									this.$router.back();
								} else {
									this.$message.error(res.msg);
								}
							})
							.catch(err => {
								loading.close();
								this.$alert(err);
							});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			}
		}
	};
</script>
<style scoped lang="scss">
	.form_box {
		max-width: 800px;
	}

	.form-item-box {
		width: 300px
	}
</style>
